@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@font-face {
  font-family: 'GT-Super-Regular';
  src: url('/src/assets/Fonts/GT-Super-Display-Regular.woff2') format('woff2');
}

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' ! important;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  /* color: orange; */
}

/* a:hover {
  color: #57bcff;
} */

.ql-container {
  font-size: 15px !important;
}