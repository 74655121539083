.App{
    width:100%;
    min-height: 100vh; 
    box-sizing: border-box;
    overflow-x:hidden;
}


main {
    /* min-height: 500px; */
    position: relative;
    margin-top: 110px;
    min-height: calc(100vh - 202px);
}

main::after{
    content: '';
}

hr {
    border-color: #e3e3e3
}

.dashed-border {
    border-style: dashed !important;
}

.no-underline {
    text-decoration: none !important
}
a.hover-underline:hover {
    text-decoration: underline !important;
}

.m-0 {
    margin: 0 !important;
}

.grayBlueGradient {
    background: rgb(2,98,194);
    background: linear-gradient(90deg, rgba(2,98,194,1) 0%, rgba(124,124,124,1) 86%);
}

.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

textarea {
    margin-top: 5px;
    padding: 5px;
}

label {
    color : black ! important;
    /* font-weight: 500 ! important; */
}

.css-u4tvz2-MuiFormLabel-root{
    font-size: 14pt ! important;
}

.blue {
    color : greenyellow ! important;
}

.math-primary-text-colour {
    color: #1976d2 ! important;
}



.english-primary-text-colour {
    color: #E11770 ! important;
}

.science-primary-text-colour {
    color: #38B4BA ! important;
}



.bg-blue {
    background-color: greenyellow ! important;
}

.bd-blue {
    border-color: greenyellow ! important;
}

/*.badges {*/
/*    background-color: #F2F7FC;*/
/*    color: #478ED5;*/
/*    border: none !important;*/
/*    padding: 5px 20px;*/
/*    margin: 0 10px 5px 0;*/
/*    border-radius: 15px;*/
/*    font-size: 15px;*/
/*    box-shadow: #478ED5 1px 1px 2px;*/
/*}*/



.grey {
    color: #5A5A5A ! important;
}

.bd-grey {
    border:1px solid #adadad ! important;
}

span {
    overflow-wrap: anywhere;
}

button {
    text-wrap: nowrap;
}
.green {
    color: #00AB55;
}