.profile-container, .qualification-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.profile-container .name-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 ! important;
    margin-top: 1rem ! important;
    margin-bottom: 1rem ! important;
}

.button-box > div {
    display: flex;
    gap: 0.5rem;
}

.button-box > div > button{
    flex: 1;
}

.step-title {
    font-size: 24pt;
    text-align: center;
    color: #0099ff;
    font-weight: bold;
}

.button-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: 100%;
    margin-top: 5rem;
}

.button-box button {
    width: auto;
}

.MuiButtonBase-root input[type=file]{
    display: none;
}

.image-input {
    background-color: #00000000!important;
    width: fit-content;
    padding : 0!important;
    /* max-height: 50px;
    max-width: 50px; */
}

.profile-container .avatar {
    width: 200px; height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    border: 1px solid black;
    position: relative;
}

.profile-container .avatar:hover{
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 1);
}

.profile-container .term-label {
    font-weight: 800 !important;
}

.profile-container .add-icon {
    position: absolute;
    right: 6%;
    bottom: 6%;
    border-radius: 50%;
    background-color: white;
}


.second-control {
    display: none;
    gap: 0.5rem;
    flex-direction: column;
}

.first-control {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.image-input img{
    height: 200px;
    width: auto;
}

.box {
    width: 100%;
}

.sign-up-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 1rem;
}


input {
    margin-top: 5px;
    padding: 5px;
    margin-right: 10px;
    line-height: 1.2em;
}

.name {
    flex: 1;
    width: auto;
}

.mb-1 {
    margin-bottom: 0.5rem;
}

.qualification {
    display: flex;
}

.gender-select > label{
    color: rgb(105, 105, 105) ! important;
}

.profile-container .avatar-buttons {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}
/* Chrome, Safari, Edge, Opera */
.name-box input::-webkit-outer-spin-button,
.name-box input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.name-box input[type=number] {
    -moz-appearance: textfield;
}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 1050px) {}

@media screen and (max-width: 899px) {}

@media screen and (max-width: 768px) {
    .study-answer-box{
        display: flex;
        width: 100%;
    }
}

@media screen and (max-width: 525px) {

    .page-heading {
        text-align: center;
    }

    .name {
        flex: auto;
        width: 100%;
    }

    .button-box button {
        flex: auto;
        width: 100%;
    }

    .step-title {
        font-size: 20pt;
    }

    .second-control {
        display: flex;
    }
    .answer-box {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    .button-box > div{
        flex: 1;
    }
}